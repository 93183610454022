<template>
  <div id="measuringToolsPlan">
    <el-dialog
      :title="measuringToolsPlanFormTitle"
      width="1500px"
      :visible.sync="measuringToolsPlanDialogVisible"
      :close-on-click-modal="false"
      @close="measuringToolsPlanDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="measuringToolsPlanFormRef"
        :model="measuringToolsPlanForm"
        :rules="measuringToolsPlanFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="检定计划名称" prop="planName">
              <el-input
                v-model="measuringToolsPlanForm.planName"
                placeholder="请输入检定计划名称"
                clearable
                :disabled="measuringToolsPlanFormTitle !== '新增计量器具周期检定计划表'
                  && measuringToolsPlanFormTitle !== '修改计量器具周期检定计划表'
                  && measuringToolsPlanFormTitle !== '计量器具周期检定计划表详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="measuringToolsPlanFormTitle === '新增计量器具周期检定计划表'
              || measuringToolsPlanFormTitle === '修改计量器具周期检定计划表'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="planDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="toolName"
              title="计量器具名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="toolNo"
              title="计量器具编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="january"
              title="一月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="february"
              title="二月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="march"
              title="三月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="april"
              title="四月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="may"
              title="五月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="june"
              title="六月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="july"
              title="七月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="august"
              title="八月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="september"
              title="九月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="october"
              title="十月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="november"
              title="十一月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="december"
              title="十二月"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="measuringToolsPlanFormTitle === '新增计量器具周期检定计划表'
                || measuringToolsPlanFormTitle === '修改计量器具周期检定计划表'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="measuringToolsPlanForm.reviewResult"
                :disabled="measuringToolsPlanFormTitle !== '审核计量器具周期检定计划表'
                  && measuringToolsPlanFormTitle !== '计量器具周期检定计划表详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="measuringToolsPlanDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="measuringToolsPlanFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="measuringToolsPlanFormTitle === '计量器具周期检定计划表详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="检定计划名称">
        <el-input v-model="searchForm.planName" placeholder="请输入检定计划名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['TOOLS_PLAN_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="measuringToolsPlanPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="planName" label="检定计划名称" />
      <el-table-column prop="editor" label="制表人" />
      <el-table-column label="制表日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editDate">{{ scope.row.editDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="审核日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['TOOLS_PLAN_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['TOOLS_PLAN_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['TOOLS_PLAN_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="measuringToolsPlanPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMeasuringToolsPlan,
  deleteMeasuringToolsPlan,
  updateMeasuringToolsPlan,
  selectMeasuringToolsPlanInfo,
  selectMeasuringToolsPlanList,
  reviewMeasuringToolsPlan
} from '@/api/quality/measuringToolsPlan'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      measuringToolsPlanDialogVisible: false,
      measuringToolsPlanFormTitle: '',
      measuringToolsPlanForm: {
        id: '',
        planName: '',
        reviewResult: '',
        status: '',
        planDetailJson: '',
        taskId: ''
      },
      measuringToolsPlanFormRules: {
        planName: [{ required: true, message: '检定计划名称不能为空', trigger: ['blur', 'change']}]
      },
      measuringToolsPlanPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        planName: ''
      },
      planDetailList: []
    }
  },
  created () {
    selectMeasuringToolsPlanList(this.searchForm).then(res => {
      this.measuringToolsPlanPage = res
    })
  },
  methods: {
    measuringToolsPlanDialogClose () {
      this.$refs.measuringToolsPlanFormRef.resetFields()
      this.planDetailList = []
    },
    measuringToolsPlanFormSubmit () {
      if (this.measuringToolsPlanFormTitle === '计量器具周期检定计划表详情') {
        this.measuringToolsPlanDialogVisible = false
        return
      }
      this.$refs.measuringToolsPlanFormRef.validate(async valid => {
        if (valid) {
          if (this.measuringToolsPlanFormTitle === '新增计量器具周期检定计划表') {
            this.measuringToolsPlanForm.id = ''
            this.measuringToolsPlanForm.status = 1
            this.measuringToolsPlanForm.planDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addMeasuringToolsPlan(this.measuringToolsPlanForm)
          } else if (this.measuringToolsPlanFormTitle === '修改计量器具周期检定计划表') {
            this.measuringToolsPlanForm.planDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateMeasuringToolsPlan(this.measuringToolsPlanForm)
          } else if (this.measuringToolsPlanFormTitle === '审核计量器具周期检定计划表') {
            this.measuringToolsPlanForm.status = 2
            await reviewMeasuringToolsPlan(this.measuringToolsPlanForm)
          }
          this.measuringToolsPlanPage = await selectMeasuringToolsPlanList(this.searchForm)
          this.measuringToolsPlanDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.measuringToolsPlanFormTitle = '新增计量器具周期检定计划表'
      this.measuringToolsPlanDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMeasuringToolsPlan(row.id)
        if (this.measuringToolsPlanPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.measuringToolsPlanPage = await selectMeasuringToolsPlanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.measuringToolsPlanFormTitle = '修改计量器具周期检定计划表'
      this.measuringToolsPlanDialogVisible = true
      this.selectMeasuringToolsPlanInfoById(row.id)
    },
    handleReview (index, row) {
      this.measuringToolsPlanFormTitle = '审核计量器具周期检定计划表'
      this.measuringToolsPlanDialogVisible = true
      this.selectMeasuringToolsPlanInfoById(row.id)
    },
    handleInfo (index, row) {
      this.measuringToolsPlanFormTitle = '计量器具周期检定计划表详情'
      this.measuringToolsPlanDialogVisible = true
      this.selectMeasuringToolsPlanInfoById(row.id)
    },
    selectMeasuringToolsPlanInfoById (id) {
      selectMeasuringToolsPlanInfo(id).then(res => {
        this.measuringToolsPlanForm.id = res.id
        this.measuringToolsPlanForm.planName = res.planName
        this.measuringToolsPlanForm.reviewResult = res.reviewResult
        this.measuringToolsPlanForm.status = res.status
        this.measuringToolsPlanForm.taskId = res.taskId
        this.planDetailList = res.planDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMeasuringToolsPlanList(this.searchForm).then(res => {
        this.measuringToolsPlanPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMeasuringToolsPlanList(this.searchForm).then(res => {
        this.measuringToolsPlanPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMeasuringToolsPlanList(this.searchForm).then(res => {
        this.measuringToolsPlanPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['TOOLS_PLAN_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `计量器具周期检定计划表${'-'+this.measuringToolsPlanForm.planName}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
