import axios from '@/common/axios'
import qs from 'qs'

export function addMeasuringToolsPlan (data) {
  return axios({
    method: 'post',
    url: '/quality/toolsPlan/add',
    data: qs.stringify(data)
  })
}

export function deleteMeasuringToolsPlan (id) {
  return axios({
    method: 'delete',
    url: '/quality/toolsPlan/delete/' + id
  })
}

export function updateMeasuringToolsPlan (data) {
  return axios({
    method: 'put',
    url: '/quality/toolsPlan/update',
    data: qs.stringify(data)
  })
}

export function selectMeasuringToolsPlanInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/toolsPlan/info/' + id
  })
}

export function selectMeasuringToolsPlanList (query) {
  return axios({
    method: 'get',
    url: '/quality/toolsPlan/list',
    params: query
  })
}

export function reviewMeasuringToolsPlan (data) {
  return axios({
    method: 'put',
    url: '/quality/toolsPlan/updateReview',
    data: qs.stringify(data)
  })
}
